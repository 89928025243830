.highlight{
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      z-index: 99;
      border: 1px solid #0087ED;
      background-color: rgba(0, 136, 231, 0.4);
      border-radius: 4px;
      box-sizing: border-box;
      animation-name: highlight-effect;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
    }
  }
  @keyframes highlight-effect {
    0%   {background-color: rgba(0, 136, 231, 0.4);border:1px solid #0087ED;}
    90% {background-color: rgba(0, 136, 231, 0.4);border:1px solid #0087ED;}
    100% {background-color: rgba(0, 136, 231, 0);border: none;}
  }



.fade {
  -webkit-animation:fade 1s ease forwards;
  animation: fade 1s ease forwards;
}
@-webkit-keyframes fade {
  from {opacity: 0}
  to {opacity: 1}
}
@keyframes fade {
  from {opacity: 0}
  to {opacity: 1}
}

.slideLeft {
  -webkit-animation:slideLeft 1s ease forwards;
  animation:slideLeft 1s ease forwards;
}
@-webkit-keyframes slideLeft {
  0% {right: -100%;}
  100% {right: 0}
}
@keyframes slideLeft {
  0% {right: -100%;}
  100% {right: 0}
}

.slideRight {
  -webkit-animation:slideRight 1s ease forwards;
  animation:slideRight 1s ease forwards;
}
@-webkit-keyframes slideRight {
  0% {left: -100%;}
  100% {left: 0}
}
@keyframes slideRight {
  0% {left: -100%;}
  100% {left: 0}
}

.slideUp {
  -webkit-animation:slideUp 1s ease forwards;
  animation:slideUp 1s ease forwards;
}
@-webkit-keyframes slideUp {
  0% {bottom:-100%; left: 0;}
  100% {bottom: 0; left:0;}
}
@keyframes slideUp {
  0% {bottom:-100%; left: 0;}
  100% {bottom: 0; left:0;}
}

.slideDown {
  -webkit-animation:slideDown 1s ease forwards;
  animation:slideDown 1s ease forwards;
}
@-webkit-keyframes slideDown {
0% {top:-100%; left: 0;}
100% {top: 0; left:0;}
}
@keyframes slideDown {
0% {top:-100%; left: 0;}
100% {top: 0; left:0;}
}
