@import 'abstract/variables';
@import 'abstract/mixins';
@import 'abstract/icons';
@import 'abstract/fonts';
@import 'abstract/animation.scss';
@import '@ngx-share/button/themes/default/default-theme';


@import url( 'https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons');

// @import '@angular/material/prebuilt-themes/indigo-pink.css';


*, *:before, *:after {box-sizing: border-box;}
html, body {width:100%; font-family: 'Gotham Book', Arial, Georgia,serif;overflow-y: hidden; color:$bodytextcolor; font-size: 14px; background: $bodybackground;
  &.prototype-page{
      overflow: auto;
  }
}
button, input, select {outline: none;}
ul, ol, li, h1, h2, h3, h4, h5, h6 {margin: 0; padding:0; list-style-type: none;}
.maincontainer {width:100%; min-height: 100vh; position: relative;}

.remove-scroll{
  overflow-x: hidden !important;
}
.default-cursor {
  cursor: default !important;
}
.drag-cursor{
    cursor: url(../assets/images/drag-cursor.png), move !important;
}

.tutorialBox {position: absolute; z-index: -2; width: 300px; box-shadow: 0 2px 16px rgba(0, 0, 0, 0.29); border-radius: 4px;
  background: #0070e0; padding: 15px; opacity: 0; transition: opacity ease-in-out 0.4s; transform: scale(0);
  h3 {font-family: "Gotham Bold", Arial, Georgia, serif; font-size: 13px; color: #fff; margin: 0 0 4px; }
  p {font-size: 12px; color: #fff; margin: 0 0 20px; font-weight: 400;}
  .dots {float: left; padding: 10px 0 0 0; line-height: 11px;
    span {width: 6px; height: 6px; border-radius: 20px; background: #fff; opacity: 0.4; display: inline-block; vertical-align: top; margin: 0 5px 0 0;
      &.active {opacity: 1;}
    }
  }

  .buttons {float: right;
    button {cursor: pointer; background: none; border: none; font-size: 13px; height: 25px; padding: 0 10px; border-radius: 3px;
      &.backButton {color: #fff;}
      &.nextButton {margin: 0 0 0 10px; background: #fff; color: #000; width: 60px;}
    }
  }

  .closeButton {position: absolute; right: 5px; cursor: pointer; top:5px; z-index: 9; color: #fff; font-size: 9px;
    width: 12px; height: 12px; opacity: 0.7; text-align: center;}

  &:before {content: ''; position: absolute;}
  &.active {
    opacity: 1; z-index: 2;  transform: scale(1);
  }
}


.passwordSet {width: 100%; clear: both; float: left; background: #ebf1fb; border-radius: 0 0 4px 4px; padding: 15px 20px 5px 20px;
  h3 {font-size: 13px; font-family: 'Gotham Bold', Arial, Georgia, serif; display: block; margin: 0 0 10px 0;
    span {float:right; background: #8291a4; cursor: pointer; width: 28px; height:16px; position: relative; z-index: 2; border-radius: 50px;
      &:before {content: ''; position: absolute; width: 12px; height: 12px; border-radius: 50px; z-index: 1; background: #fff;
        top:50%; transform: translateY(-50%); left: 2px; transition: all ease-in-out 0.3s;}

      &.active {background: #00c852;
        &:before {left:14px;}
      }
    }
  }

  .passwordInsert {width: 100%; float: left; clear: both; margin: 0 0 10px;
    input {width: calc(100% - 85px); height: 32px; padding: 5px 12px; border-radius: 4px; background: #fff; border: none;}
    button {font-size: 12px; font-family: 'Gotham Bold', Arial, Georgia, serif; text-align: center; float: right; width: 75px; height: 32px;
      border-radius: 4px; cursor: pointer; border: none; transition: all ease-in-out 0.3s;
      &.save {background:#ebf1fb; border: 1px solid #0070e0; color: #0070e0;
        &:hover {background:#0070e0; color: #fff;}
      }
      &.saved {background: #00c852; color: #fff;}
    }
  }

}

.passwordProtected {
  position: fixed; top:0; left:0; z-index: 9999; width: 100%; height: 100%; background: rgba(29, 44, 65,0.95);
  display: flex; justify-content: center; align-items: center; transform: scale(0); opacity: 0; transition: all ease-in-out 0.3s;

  .boxholder {width: 100%; max-width: 670px; padding: 0 30px; text-align: center;
    h3 {font-size: 32px; color: #fff; margin: 0 0 15px; font-weight: normal;}
    h4 {font-size: 16px; color: #fff; margin: 0 0 35px; font-weight: normal;}

    .inputRow {text-align: center; display: inline-block; position: relative; height: 42px; width: 430px;
      input {width: 262px; height: 42px; border-radius: 4px; float: left; border: none; padding: 8px 12px;
        &.redBorder {border: 1px solid #ff3c3c;}
      }

      .errorMsg {position: absolute; display: block; background: #ff3c3c; font-size: 14px; color: #fff; border-radius: 5px;
        top:calc(100% + 8px); left:0; padding: 7px 20px;
        &:before {content:''; position: absolute; top:-7px; left:50%; transform: translateX(-50%); border-bottom: 8px solid #ff3c3c;
          border-left: 12px solid transparent; border-right: 12px solid transparent;}
      }

      button {width: 155px; height: 42px; border-radius: 4px; background: #EB2F96; border: none; color: #fff; font-size: 15px; cursor: pointer;
        float: right; transition: all ease-in-out 0.3s;
        &:hover {background: #CE1A7D;}
      }
    }
  }

  &.active {transform: scale(1); opacity: 1;}

}

@media only screen and (max-width:768px){
  .tutorialBox {display: none;}

  .passwordProtected {
    .boxholder {
      h3 {font-size: 24px; line-height: 32px;}
      h4 {font-size: 14px; line-height: 20px;}

      .inputRow {width: 100%;
        input {width: 100%; margin: 0 0 12px;}

        .errorMsg {top:auto; bottom:calc(100% + 8px);
          &:before {top:auto; bottom: -7px; border-bottom: none; border-top: 8px solid #ff3c3c;}
        }

        button {width: 100%;}
      }

    }
  }
}

// Common toggle button for UIW start
.switchToggle {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  margin: 0 10px 0 0;
  input { 
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .toggleButton {
      background-color: #00D659;
    }
    
    &:focus + .toggleButton {
      box-shadow: 0 0 1px #00D659;
    }
    
    &:checked + .toggleButton:before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
    }
  }
  .toggleButton {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #C0C3CE;
    -webkit-transition: .2s;
    transition: .2s;
    &::before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .2s;
      transition: .2s;
    }
    &.round {
      border-radius: 34px;
      &::before {
        border-radius: 50%;
      }
    }
  }
}
// Common toggle button for UIW end

// Common Disable class start
.commDisableDiv {
  cursor: default;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.6);
  filter: grayscale(1);
  em, span.notification {
    opacity: .6;
  }
}
.commDisableBtn {
  cursor: default;
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.6;
}

// Common Disable class end

// Toast container width increase for 500 error
.toast-container {
  .widthIncrease.ngx-toastr {
    width: 390px !important;
    p {
      margin-bottom: 6px;
    }
  }
}


