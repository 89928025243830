@charset "UTF-8";

@font-face {
  font-family: "uiw";
  src:url("../assets/fonts/uiw-fonts/uiw.eot");
  src:url("../assets/fonts/uiw-fonts/uiw.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/uiw-fonts/uiw.woff") format("woff"),
  url("../assets/fonts/uiw-fonts/uiw.ttf") format("truetype"),
  url("../assets/fonts/uiw-fonts/uiw.svg#uiw") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "uiw", sans-serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "uiw", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home-icon:before {
  content: "\61";
}
.icon-project-icon:before {
  content: "\62";
}
.icon-notification-icon:before {
  content: "\63";
}
.icon-setting-icon:before {
  content: "\64";
}
.icon-up-arrow-icon:before {
  content: "\66";
}
.icon-down-arrow-icon:before {
  content: "\67";
}
.icon-hotspot-icon:before {
  content: "\69";
}
.icon-timer-icon:before {
  content: "\6a";
}
.icon-todo-icon:before {
  content: "\6b";
}
.icon-share:before {
  content: "\6c";
}
.icon-comment:before {
  content: "\6d";
}
.icon-design:before {
  content: "\6e";
}
.icon-brief:before {
  content: "\6f";
}
.icon-select:before {
  content: "\70";
}
.icon-add-comment:before {
  content: "\71";
}
.icon-stories:before {
  content: "\72";
}
.icon-activity:before {
  content: "\73";
}
.icon-filter:before {
  content: "\74";
}
.icon-icon-pause:before {
  content: "\75";
}
.icon-icon-play:before {
  content: "\76";
}
.icon-icon-plus:before {
  content: "\77";
}
.icon-icon-restart:before {
  content: "\78";
}
.icon-icon-tick:before {
  content: "\79";
}
.icon-icon-timer:before {
  content: "\7a";
}
.icon-icon-stories:before {
  content: "\41";
}
.icon-icon-mobile:before {
  content: "\43";
}
.icon-icon-no-trans:before {
  content: "\44";
}
.icon-icon-transition:before {
  content: "\45";
}
.icon-icon-user:before {
  content: "\46";
}
.icon-icon-right-2:before {
  content: "\47";
}
.icon-icon-right:before {
  content: "\48";
}
.icon-icon-left-4:before {
  content: "\49";
}
.icon-icon-left-3:before {
  content: "\4a";
}
.icon-icon-drop:before {
  content: "\4b";
}
.icon-icon-left-2:before {
  content: "\4c";
}
.icon-icon-chat:before {
  content: "\4d";
}
.icon-icon-brief:before {
  content: "\4e";
}
.icon-icon-email:before {
  content: "\50";
}
.icon-icon-drop-design:before {
  content: "\51";
}
.icon-icon-eye:before {
  content: "\52";
}
.icon-icon-minus:before {
  content: "\54";
}
.icon-icon-more:before {
  content: "\56";
}
.icon-icon-flow:before {
  content: "\57";
}
.icon-icon-close:before {
  content: "\59";
}
.icon-icon-design:before {
  content: "\5a";
}
.icon-icon-desktop:before {
  content: "\30";
}
.icon-icon-full-screen:before {
  content: "\31";
}
.icon-icon-help:before {
  content: "\32";
}
.icon-icon-done:before {
  content: "\33";
}
.icon-icon-double-tap:before {
  content: "\34";
}
.icon-icon-history:before {
  content: "\35";
}
.icon-icon-pass:before {
  content: "\36";
}
.icon-icon-subtask:before {
  content: "\55";
}
.icon-icon-summary:before {
  content: "\37";
}
.icon-icon-swipe-down:before {
  content: "\38";
}
.icon-icon-swipe-left:before {
  content: "\39";
}
.icon-icon-swipe-right:before {
  content: "\21";
}
.icon-icon-swipe-up:before {
  content: "\22";
}
.icon-icon-tap:before {
  content: "\23";
}
.icon-icon-pinch-out:before {
  content: "\24";
}
.icon-icon-pinch-in:before {
  content: "\25";
}
.icon-icon-hold:before {
  content: "\26";
}
.icon-icon-info:before {
  content: "\28";
}
.icon-icon-left:before {
  content: "\29";
}
.icon-icon-down-2-1:before {
  content: "\2b";
}
.icon-icon-down-2:before {
  content: "\2c";
}
.icon-icon-action:before {
  content: "\2d";
}
.icon-icon-bin:before {
  content: "\2e";
}
.icon-icon-attachment:before {
  content: "\2f";
}
.icon-icon-down:before {
  content: "\3a";
}
.icon-icon-read:before {
  content: "\42";
}
.icon-icon-fade:before {
  content: "\58";
}
.icon-icon-hotspot-link:before {
  content: "\53";
}
.icon-icon-pencil:before {
  content: "\27";
}
.icon-slide-up:before {
  content: "\3b";
}
.icon-icon-hover:before {
  content: "\4f";
}
.icon-primary-icon:before {
  content: "\3d";
}
.icon-icon-download:before {
  content: "\2a";
}
.icon-icon-download2:before {
  content: "\3e";
}
.icon-sketch-download-icon:before {
  content: "\3f";
}
.icon-search-icon:before {
  content: "\68";
}
.icon-icon-collapse:before {
  content: "\40";
}
.icon-icon-multi-screen:before {
  content: "\5b";
}
.icon-icon-varients:before {
  content: "\5d";
}
.icon-caret-down:before {
  content: "\5e";
}
.icon-info-fill:before {
  content: "\5f";
}
.icon-unlinked-icon:before {
  content: "\60";
}
.icon-icon-left-2:before {
  content: "\4c";
}
.icon-icon-double-tap:before {
  content: "\34";
}
.icon-sketch-diamond:before {
  content: "\7b";
}
.icon-pencil:before {
  content: "\7c";
}
.icon-featurenotes:before {
  content: "\7e";
}
.icon-icon-image-sync:before {
  content: "\5c";
}
.icon-icon-unlinked2:before {
  content: "\e000";
}
.icon-icon-set-launch-screen:before {
  content: "\e001";
}
.icon-icon-document:before {
  content: "\e002";
}
.icon-smartwatch-icon:before {
  content: "\e003";
}
.icon-mobile-icon:before {
  content: "\e004";
}
.icon-tab-icon:before {
  content: "\e005";
}
.icon-desktop-icon:before {
  content: "\e006";
}
.icon-icon-download-new:before {
  content: "\e007";
}
.icon-download-carousel:before {
  content: "\e008";
}
.icon-triangle:before {
  content: "\e009";
}
.icon-copy-icon:before {
  content: "\e00a";
}
.icon-swapping:before {
  content: "\e00b";
}
.icon-social-apple:before {
  content: "\e00c";
}
.icon-social-android:before {
  content: "\e00d";
}
.icon-promote:before {
  content: "\e00e";
}
.icon-figma-icon:before {
  content: "\e00f";
}
.icon-android-new-icon:before {
  content: "\e010";
}
.icon-ios-new-icon:before {
  content: "\e011";
}
.icon-no-search-result:before {
  content: "\e012";
}
.icon-search-new:before {
  content: "\e013";
}
.icon-android-plain-icon:before {
  content: "\e014";
}
.icon-ios-plain-icon:before {
  content: "\e015";
}
.icon-expand-icon:before {
  content: "\e016";
}
.icon-backwardconnector:before {
  content: "\e017";
}
.icon-forwardconnector:before {
  content: "\e018";
}
.icon-filter-1:before {
  content: "\e019";
}
.icon-sprint:before {
  content: "\e01a";
}
.icon-no-story:before {
  content: "\e01b";
}
.icon-down-dir:before {
  content: "\e01c";
}
.icon-up-dir:before {
  content: "\e01d";
}
.icon-timer-elapsed:before {
  content: "\e01e";
}
.icon-sort:before {
  content: "\e01f";
}
.icon-play:before {
  content: "\e020";
}
.icon-pause:before {
  content: "\e021";
}
.icon-upload:before {
  content: "\e022";
}
.icon-finish-story:before {
  content: "\e023";
}
.icon-resubmit-story:before {
  content: "\e024";
}
.icon-close-new:before {
  content: "\e025";
}
.icon-star-fill:before {
  content: "\e027";
}
.icon-star:before {
  content: "\e026";
}
.icon-evaluation-icon:before {
  content: "\e028";
}
.icon-documenticon:before {
  content: "\65";
}
.icon-figmasketchicon:before {
  content: "\42";
}
.icon-imageicon:before {
  content: "\57";
}
.icon-zipicon:before {
  content: "\33";
}
.icon-design-status:before {
  content: "\3c";
}
.icon-hero-screen:before {
  content: "\7d";
}
.icon-pen-add:before {
  content: "\e029";
}
.icon-show-varient:before {
  content: "\e02a";
}
.icon-linked-screen:before {
  content: "\e02b";
}
.icon-copy-new:before {
  content: "\e02c";
}
.icon-trash-new:before {
  content: "\e02d";
}
.icon-new-connector:before {
  content: "\e02e";
}
.icon-set-launch-screen:before {
  content: "\e02f";
}
.icon-icon-logout2:before {
  content: "\e030";
}
.icon-loading:before {
  content: "\e031";
}
.icon-tick-mark:before {
  content: "\e032";
}
.icon-validate-design:before {
  content: "\e033";
}
.icon-validate-story:before {
  content: "\e034";
}
.icon-thumbs-up:before {
  content: "\e036";
}
.icon-thumbs-down:before {
  content: "\e035";
}
.icon-eye-off:before {
  content: "\e037";
}
.icon-component-usage:before {
  content: "\e038";
}
.icon-design-layer:before {
  content: "\e039";
}
.icon-smartwatch-icon-unfilled:before {
  content: "\e03a";
}
.icon-documents-new-icon:before {
  content: "\e03b";
}
.icon-white-board-new-icon:before {
  content: "\e03c";
}
.icon-buildcard-info-icon:before {
  content: "\e03d";
}
.icon-folder:before {
  content: "\e03e";
}
.icon-unresolved:before {
  content: "\e03f";
}
.icon-resolved:before {
  content: "\e040";
}
.icon-reply-arrow:before {
  content: "\e041";
}
.icon-msg-send:before {
  content: "\e042";
}
.icon-icon-read:before {
  content: "\e043";
}
.icon-click-upload:before {
  content: "\e044";
}
.icon-flowchart-icon:before {
  content: "\e045";
}
.icon-pause-new:before {
  content: "\e046";
}
.icon-play-new:before {
  content: "\e047";
}
.icon-new-filter:before {
  content: "\e048";
}
.icon-prototype-play:before {
  content: "\e049";
}
.icon-request-access:before {
  content: "\e04a";
}
.icon-calender:before {
  content: "\e04b";
}
.icon-zoomin:before {
  content: "\e04c";
}
.icon-zoomout:before {
  content: "\e04d";
}
.icon-newfitscreen:before {
  content: "\e04e";
}
.icon-switchtoggleicon:before {
  content: "\e04f";
}
.icon-substoryicon:before {
  content: "\e050";
}
.icon-responsive-screen:before {
  content: "\e051";
}
.icon-additional-detail:before {
  content: "\e052";
}