.c-btn {
    background: #fff;
    border: 1px solid #ccc;
    color: #333; }
  .selected-list .c-list .c-token {
    background: #0079FE; }
    .selected-list .c-list .c-token .c-label {
      color: #fff; }
    .selected-list .c-list .c-token .c-remove svg {
      fill: #fff; }
  .selected-list .c-angle-down svg, .selected-list .c-angle-up svg {
    fill: #333; }
  .dropdown-list ul li:hover {
    background: #F0F5FF;
  }
  .arrow-up, .arrow-down {
    border-bottom: 15px solid #fff; }
  .arrow-2 {
    border-bottom: 15px solid #ccc; }
  .list-area {
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: 0px 1px 5px #959595; }
  .select-all {
    border-bottom: 1px solid #ccc; }
  .list-filter {
    border-bottom: 1px solid #ccc; }
    .list-filter .c-search svg {
      fill: #888; }
    .list-filter .c-clear svg {
      fill: #888; }
  .pure-checkbox input[type="checkbox"]:focus + label:before, .pure-checkbox input[type="checkbox"]:hover + label:before {
    border-color: #0079FE;
    background-color: #f2f2f2; }
  .pure-checkbox input[type="checkbox"] + label {
    font-size: 16px;
    line-height: 32px;
    color: #000; }
  .pure-checkbox input[type="checkbox"] + label:before {
    color: #0079FE;
    border: 1px solid #C0C3CE; }
  .pure-checkbox input[type="checkbox"] + label:after {
    background-color: #0079FE; }
  .pure-checkbox input[type="checkbox"]:disabled + label:before {
    border-color: #cccccc; }
  .pure-checkbox input[type="checkbox"]:disabled:checked + label:before {
    background-color: #cccccc; }
  .pure-checkbox input[type="checkbox"] + label:after {
    border-color: #000; }
  .pure-checkbox input[type="radio"]:checked + label:before {
    background-color: white; }
  .pure-checkbox input[type="checkbox"]:checked + label:before {
    background: #fff; }
  .single-select-mode .pure-checkbox input[type="checkbox"]:focus + label:before, .single-select-mode .pure-checkbox input[type="checkbox"]:hover + label:before {
    border-color: #0079FE;
    background-color: #f2f2f2; }
  .single-select-mode .pure-checkbox input[type="checkbox"] + label {
    color: #000; }
  .single-select-mode .pure-checkbox input[type="checkbox"] + label:before {
    color: transparent !important;
    border: 0px solid #0079FE; }
  .single-select-mode .pure-checkbox input[type="checkbox"] + label:after {
    background-color: transparent !important; }
  .single-select-mode .pure-checkbox input[type="checkbox"]:disabled + label:before {
    border-color: #cccccc; }
  .single-select-mode .pure-checkbox input[type="checkbox"]:disabled:checked + label:before {
    background-color: #cccccc; }
  .single-select-mode .pure-checkbox input[type="checkbox"] + label:after {
    border-color: #0079FE; }
  .single-select-mode .pure-checkbox input[type="radio"]:checked + label:before {
    background-color: white; }
  .single-select-mode .pure-checkbox input[type="checkbox"]:checked + label:before {
    background: none !important; }
  /*.selected-item {*/
  /*  background: #e9f4ff; }*/
  .btn-iceblue {
    background: #0079FE;
    border: 1px solid #ccc;
    color: #fff; }
  .pure-checkbox.selected-item input[type=checkbox]+label {
    font-family: "Gotham Bold", Arial, Georgia, serif;
  }
  .dropdown-list ul li:hover input[type=checkbox]+label {
    font-family: "Gotham Bold", Arial, Georgia, serif;
  }
  .pure-checkbox input[type=checkbox]+label:before {
    width: 22px!important;
    height: 22px!important;
    top: 43% !important;
  }
  .pure-checkbox input[type=checkbox]:checked+label:after {
    width: 11px!important;
    height: 6px!important;
    left: 5px!important;
    top: 17px!important;
  }
  .list-area div:nth-child(4){
    max-height: 181px!important;
  }
  .dropdown-list ul li {
    padding: 2px 22px !important;
  }
  .arrow-down, .arrow-up {
    visibility: hidden !important;
  }
  .dropdown-list {
    padding-top: 0 !important;
  }
  .pure-checkbox.selected-item input[type="checkbox"] + label:before, .dropdown-list ul li:hover input[type="checkbox"] + label:before {
    border: 1px solid #000;
  }
  .select-all {
    display: none;
  }
  .list-area {
    padding-top: 20px;
  }
  .list-filter {
    width: 480px;
    margin: 0 auto;
    padding: 0 !important;
    border-radius: 4px !important;
    border: none !important;
  }
  .list-filter .c-search {
    display: none !important;
  }
  .list-filter input {
    background: #F5F5F5 !important;
    border-radius: 4px !important;
    padding: 0 16px;
  }
  .selected-list .c-btn {
    font-size: 16px !important;
    line-height: 20px !important;
    color: #676B7E !important;
    border: none !important;
  }
  .list-filter input {
    padding: 0 16px !important;
  }
.selected-list .c-btn > span:first-child{
  padding: 8px 0;
}
